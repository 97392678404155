.sports {
    padding: 3rem 0.5rem 2rem;
    background: linear-gradient(180deg, #545d6d, #768093);
    text-align: center;

    .inner {
        width: 100%;
        max-width: 930px;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }

    .title {
        font-size: 1.25rem;
        color: #eef0f3;
        padding: 0 3rem;
    }

    .description {
        max-width: 40rem;
        color: black;
        padding: 0 3rem;
        margin: 0 auto 2rem;
    }
}

.sport {
    max-width: 12rem;
    min-width: 8rem;
    margin: 0 0.5rem 1rem;
    flex-grow: 1;
    display: none;
    padding: 1.5rem;

    .icon {
        width: 100%;
        padding-bottom: 100%;
        position: relative;
        color: #39d5d8;
        background: 50% 50% no-repeat;
        background-size: contain;

        $sports:
            'football', 'bowling', 'roller-hockey', 'american-football',
            'table-tennis', 'golf', 'cricket', 'video-games',
            'snooker', 'basketball', 'paralympics', 'hockey';

        @each $sport in $sports {
            &-#{$sport} {
                background-image: url('/images/icon-#{$sport}.svg');
            }
        }
    }
}

.sport:nth-child(-n+6) {
    display: block;
}

@media (min-width: 555px) {
    .sport:nth-child(-n+8) {
        display: block;
    }
}

@media (min-width: 690px) {
    .sport:nth-child(-n+10) {
        display: block;
    }
}

@media (min-width: 825px) {
    .sport {
        display: block;
    }
}
