footer {
    padding: 1.5rem 2rem;
    font-size: 80%;

    .inner {
        width: 100%;
        margin: 0 auto;
        display: flex;
        align-items: baseline;
        flex-wrap: wrap;
    }

    .btn {
        margin: 0;
        margin-left: 1rem;
    }

    span:first-child {
        flex-grow: 1;
    }

    a {
        text-decoration: none;
        color: #333;
    }

}
