.registration {
    //margin:0 4em;
    //text-align:center;
    padding: 0 1.2rem;
    max-width: 40em;
    margin: 0 auto 4em;

    .league-logo {
        margin: 0 auto;
        width:15em;
    }

    h2 {
        text-align: center;
    }

    h3 {
        font-size: inherit;
        margin: 1.8em 0 0.3em;
    }

    form {
        width: 40em;
        border: none !important;
        padding: 0;
        text-align: left;

        label {
            font-weight: bold;
            margin: 1.8em 0 0.3em;
            display: block;
        }

        .ng-touched.ng-invalid,
        &.ng-submitted .ng-untouched.ng-invalid {
            border-color: #ff5959;
        }

        .help-block {
            display: block;
            font-size: 0.8em;
            margin: 0.3em 0 2.4em;
        }
        .error {
            color: #ff5959;
        }

        h3 ~ .form-inline {
            border: 1px solid #d3d6da;
            background-color: #f9fafc;
            border-radius: 4px;
            padding: 0.6em;
            padding-bottom: 0;

            label, span {
                display: none;
            }
            .form-group {
                margin-bottom: 0.6em;
            }
            select {
                font-size: 0.8em;
                padding: 0.8rem;
            }
        }

        input[type="checkbox"] {
            margin: 0 0.6em 0 0;
            position: relative;
            top: -6px;
        }

        a[ng-click^="addRole"],
        a[ng-click^="removeRole"] {
            @extend .btn;
            @extend .btn-small;
            @extend .btn-full;
            @extend .btn-hollow;
        }
        a[ng-click^="removeRole"] {
            margin-bottom: 0.6rem;
        }

        div:nth-child(3) h3 {
            display: none;
        }

        .btn-primary {
            @extend .btn-full;
            @extend .btn-big;
        }
    }

    a {
        cursor: pointer;
        text-decoration: underline;
    }
}
