form {
    margin: 0 auto;
    width: 24rem;
    max-width: 100%;
    padding: 0 1.2rem;
    text-align: center;
}

.form-group {
    margin: 0 0 1.2rem;
}

.form-link {
    margin-top: 3rem;
    font-size: 80%;
}

select {
  -webkit-appearance: textarea;
}
select,
input[type="text"],
input[type="email"],
input[type="password"] {
    padding: 1rem;
    border-radius: 4px;
    font-size: 15px;
    background-color: #f9fafc;
    border: 1px solid #d3d6da;
    width: 100%;
    transition-property: all;
    transition-duration: 0.2s;

    &::placeholder {
        color: #768093;
    }

    &:focus {
        outline: none;
        border-color: #39d5d8;
        background: white;

        &::placeholder {
            color: #a8afbd;
        }
    }
}

.ng-invalid {
  border: 1px solid #d3d6da;
}
