.logo {
    background: url(/images/logo-full.svg) 0 50% no-repeat;
    background-size: auto 1.55rem;
    display: block;
    min-height: 1.55rem;
    width: 12rem;
    transition-property: all;
    transition-duration: 0.5s;

    span {
        display: none;
    }

    .header-auth & {
        margin: 2rem 0;
        background-position: 50% 50%;

        @media (min-width: 40em) {
            margin: 1rem 0;
        }
    }
}
