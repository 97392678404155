.alert {
    margin-bottom: 1.2rem;
    text-align: left;
    padding: 1rem;
    border: 1px solid #eef0f3;
    border-radius: 4px;
    font-size: 90%;

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    &-error,
    &-danger {
        color: #c00;

        a {
            color: black;
        }
    }

    /*&-danger {
      color: #721c24;
      background-color: #f8d7da;
      border-color: #f5c6cb;
      a {
          color: black;
      }
    }*/

    &-success {
        color: #155724;
        background-color: #d4edda;
        border-color: #c3e6cb;
    }

}
