.link {
    color: #26babd;
    text-decoration: none;
    padding: 4px 0;

    &:hover,
    &:focus {
        outline: none;
        text-decoration: underline;
    }

    &-plain {
        color: inherit;
    }
}
