.pricing {
    background: linear-gradient(315deg, #5fd9ad, #2ad4e9);
    padding: 2rem;
    text-align: center;
    color: white;

    .inner {
        margin: 0 auto;
        max-width: 32rem;
        padding: 0 2rem 2rem;
        border: 1px solid white;
        border-radius: 0.5rem;
    }

    .amount {
        background: white;
        color: #333;
        padding: 1rem;
        border-radius: 0.5rem;
        width: 12rem;
        margin: 2rem auto;

        * {
            display: block;
        }

        strong {
            font-size: 3rem;
            line-height: 3rem;
        }
        span {
            color: #768093;
            font-size: 80%;
        }
    }

    h2 {
        font-size: 2rem;
        margin-bottom: 0;
        font-weight: normal;
    }
}
