.header {
    position: relative;
    padding-left: 1rem;
    display: flex;
    align-items: stretch;
    transition: all 0.5s;

    nav {
        padding: 1em 0 0;
        align-items: stretch;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        background: white;
        opacity: 0;
        transition: opacity 0.5s, transform 0.5s, height 0.5s;
        box-shadow: 0 8px 40px rgba(23,43,99,0.17);
        transform: translate(0, -1em);
        height: 0;
        overflow: hidden;

        .link {
            display: block;
        }
    }

    .link {
        color: #333;
        padding: 1rem 1.5rem;
    }

    .logo {
        flex: 1;
    }

    .btn {
        border-radius: 0;
        transition: border-radius 0.5s;
        font-weight: bold;
        box-shadow: none;
        margin: 0;
    }

    .toggle {
        padding: 1rem 1.2rem;

        .bar {
            display: block;
            width: 1.2em;
            height: 2px;
            margin-top: 4px;
            background: #768093;
            transition: all 0.2s ease-out;
            top: 0;
            position: relative;
            border-radius: 2px;
        }


    }

    @media (min-width: 40em) {
        margin: 1rem;

        nav {
            display: flex;
            position: static;
            opacity: 1;
            box-shadow: none;
            transform: translate(0, 0);
            padding: 0;
            height: auto;
            padding-right: 1.5rem;
        }

        .toggle {
            display: none;
        }

        .btn {
            border-radius: 4px;
        }
    }
}

.menu-open {
    .header {
        nav {
            opacity: 1;
            transform: translate(0, 0);
            height: 12rem;
        }
        .toggle {
            .bar:first-child {
                transform: rotate(45deg);
                top: 6px;
            }
            .bar:nth-child(2) {
                opacity: 0;
            }
            .bar:last-child {
                transform: rotate(-45deg);
                top: -6px;
            }
        }
    }
}
