.panels {
    display: flex;
    flex-wrap: wrap;
}

.panel {
    width: 100%;
    min-height: 10rem;
    padding: 4rem 0;
    background: linear-gradient(180deg, #eef0f3, white) no-repeat;
    background-size: 100% 5rem;
    position: relative;
    text-align: center;

    .icon {
        width: 12rem;
        height: 6rem;
        margin: 0 auto;
        color: #39d5d8;

        svg {
            height: 100%;
        }
    }

    .title {
        font-size: 1.25rem;
        color: #333;
    }

    .description {
        max-width: 40rem;
        margin: 0 auto;
        padding: 0 3rem;

        em {
            font-style: normal;
            color: #333;
        }
    }

    @media (min-width: 48em) {
        width: 50%;
    }
}
