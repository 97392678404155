html, body, input, select, option, button {
    font-family: 'azo-sans-web', 'Helvetica Neue', helvetica, arial, sans-serif;
    font-size: 15px;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

html {
    height: 100%;
}

body {
    color: #404652; //#768093;
    margin: 0;
    min-height: 100%;
}

* {
    box-sizing: border-box;
}

p {
    line-height: 1.75;
}

a {
    color: #26babd;
}
