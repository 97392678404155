.hero {
    text-align: center;
    background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 40L40 0H20L0 20M40 40V20L20 40' fill='%23f9fafc' fill-rule='evenodd'/%3E%3C/svg%3E");

    .inner {
        margin: 0 auto;
        width: 100%;
        max-width: 45rem;
        padding: 8rem 2rem 8rem;
    }

    p {
        margin: 2em 0;
    }

    .tagline {
        margin: 0;
        font-size: 3rem;
        color: #333;
    }
}
