.btn {
    padding: 1em;
    color: white;
    background: #eef0f3;
    border: none;
    text-transform: uppercase;
    letter-spacing: 2px;
    border-radius: 4px;
    transition-property: all;
    transition-duration: 0.2s;
    text-decoration: none;
    display: inline-block;
    margin: 0 0.5em 1em;
    text-align: center;

    &:not([disabled]) {
        background: #39d5d8;
        cursor: pointer;
        box-shadow: 0 8px 40px rgba(23,43,99,0.17);
    }

    &:hover:not([disabled]),
    &:focus:not([disabled]) {
        outline: none;
        background: #26babd;
    }

    &-hollow {
        background: white;
        color: #eef0f3;
        border: 1px solid #eef0f3;
        box-shadow: none;

        &:not([disabled]) {
            background: white;
            color: #39d5d8;
            border: 1px solid #39d5d8;
            box-shadow: none;
        }

        &:hover:not([disabled]),
        &:focus:not([disabled]) {
            background: white;
            border-color: #768093;
            color: #768093;
        }
    }

    &-small {
        font-size: 75%;
        font-weight: bold;
    }

    &-big {
        padding: 1.25em;
    }

    &-full {
        display: block;
        width: 100%;
        margin: 0;
    }

    &:active {
        transform: scale3d(0.97, 0.97, 0.97);
        box-shadow: 0 4px 20px rgba(23,43,99,0.26);
    }


}
